import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-subscribe',
  standalone: true,
  imports: [
    SvgIconComponent,
    ReactiveFormsModule,
    FormsModule,
    RouterLink
  ],
  templateUrl: './subscribe.component.html',
  styleUrl: './subscribe.component.scss'
})
export class SubscribeComponent {
  emailField = new FormControl('', [Validators.required, Validators.email]);

  // unused for now because of changing UI
  subscribeToNewsletter() {
    console.log('Subscribing to newsletter with email:', this.emailField.value)
  }
}
