import { Component } from '@angular/core';
import { SvgIconComponent } from '../../components/svg-icon/svg-icon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    SvgIconComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './submit-bug.component.html',
  styleUrl: './submit-bug.component.scss'
})
export class SubmitBugComponent {
}
