<div class="page-content">
  <section class="sub-heading vertical-container gap-16 align-start">
    <h1 class="page-title">SECURITY STACK</h1>
    <h3 id="security-stack-title" class="background-box-always">AUTHENTICATE WITH ABSOLUTE ASSURANCE</h3>
    <p class="sub-heading-text page-content-description">Tikos innovative security stack integrates NFC, NFT, and
      Blockchain technology to
      provide a comprehensive solution for ensuring authenticity, ownership, and provenance of Tikos chips. NFC (Near
      Field Communication) enables secure, contactless communication for chip authentication, allowing users to easily
      verify the legitimacy of each product. By incorporating NFTs (Non-Fungible Tokens), Tikos offers a unique digital
      certificate of ownership, making each chip traceable and irreplaceable. Blockchain technology underpins this
      system, creating a decentralized ledger that records every transaction and transfer, ensuring transparency and
      immutability. This combination guarantees that Tikos chips remain secure, authentic, and verifiable across their
      lifecycle.</p>
    <button class="btn yellow hidden">
      GIVE IT A TRY
      <app-svg-icon iconPath="icons/call_made"></app-svg-icon>
    </button>
  </section>

  <section class="bullet-points">
    <article class="vertical-container gap-40">
      <div class="hwsw-section-part">
        <div class="title">
          <app-svg-icon iconPath="icons/check_40" [width]="40" [height]="40" class="hide-on-phones"></app-svg-icon>
          <app-svg-icon iconPath="icons/check_40" [width]="22" [height]="22" viewBox="0 0 40 40"
                        class="hide-on-big-screen"></app-svg-icon>
          <span>NFC Technology</span>
        </div>
        <div class="description">
          NFC (Near Field Communication) technology enables two devices to communicate wirelessly when they are close
          together, typically within 4 centimeters. It uses electromagnetic fields to transmit data between an NFC
          reader and an NFC tag or another NFC-enabled device. The devices must have compatible NFC chips, and the
          communication is typically short-range, secure, and used for tasks like contactless payments, data transfer,
          and device pairing. NFC is passive (one device initiates communication) and operates based on existing RFID
          technology but with added security and protocols for data exchange.
        </div>
      </div>

      <div class="hwsw-section-part">
        <div class="title">
          <app-svg-icon iconPath="icons/check_40" [width]="40" [height]="40" class="hide-on-phones"></app-svg-icon>
          <app-svg-icon iconPath="icons/check_40" [width]="22" [height]="22" viewBox="0 0 40 40"
                        class="hide-on-big-screen"></app-svg-icon>
          <span>NFT Technology</span>
        </div>
        <div class="description">
          NFTs (Non-Fungible Tokens) are digital assets that use blockchain technology to verify ownership and
          authenticity of unique items, such as art, music, or collectibles. Unlike cryptocurrencies, which are
          interchangeable, NFTs are indivisible and distinct, with each token representing a specific asset. When
          someone buys an NFT, they acquire ownership recorded on the blockchain, a decentralized ledger, ensuring the
          asset's provenance is traceable and secure. Smart contracts (self-executing agreements coded into the
          blockchain) automatically enforce terms, like transferring ownership or paying royalties, without
          intermediaries. NFTs are commonly traded on platforms like Ethereum and Solana.
        </div>
      </div>

      <div class="hwsw-section-part">
        <div class="title">
          <app-svg-icon iconPath="icons/check_40" [width]="40" [height]="40" class="hide-on-phones"></app-svg-icon>
          <app-svg-icon iconPath="icons/check_40" [width]="22" [height]="22" viewBox="0 0 40 40"
                        class="hide-on-big-screen"></app-svg-icon>
          <span>Blockchain</span>
        </div>
        <div class="description">
          Blockchain is a decentralized, digital ledger that records transactions across a network of computers in a
          secure, transparent, and immutable manner. Each "block" contains data, such as transaction details, which is
          linked to the previous block, forming a "chain." This structure ensures that once data is recorded, it cannot
          be altered without consensus from the network. Blockchain is most commonly associated with cryptocurrencies
          like Bitcoin, but it is also used for various applications, including supply chain management, smart
          contracts, and secure data sharing, due to its security, transparency, and resistance to fraud.
        </div>
      </div>

      <app-subscribe/>
    </article>
  </section>
</div>
