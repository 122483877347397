import { Component, ElementRef, HostListener, input, signal } from '@angular/core';
import { NgClass } from "@angular/common";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { RouterLink, RouterLinkActive } from "@angular/router";

export interface DropdownMenuItem {
  label: string;
  route?: string;
  link?: string;
}

@Component({
  selector: 'app-dropdown-menu',
  standalone: true,
  imports: [
    NgClass,
    SvgIconComponent,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './dropdown-menu.component.html',
  styleUrl: './dropdown-menu.component.scss'
})
export class DropdownMenuComponent {

  label = input.required<string>();
  items = input.required<DropdownMenuItem[]>();
  absolute = input<boolean>(false);

  menuOpened = signal(false);

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.menuOpened.set(false);
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.menuOpened.set(false);
    }
  }

  constructor(private elementRef: ElementRef) {
  }
}
