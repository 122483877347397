<div class="page-content">
  <section class="vertical-container gap-40 align-start submit-bug">
    <h1 id="submit-bug-title" class="background-box-always page-title">SUBMIT A BUG</h1>
    <div class="page-content-description">Thank you for helping us improve our product by reporting any bugs or issues
      you encounter. Please provide
      detailed information about the problem you're experiencing so that our team can investigate and address it
      promptly. Fill out the form below to submit a bug report.
    </div>
    <form class="vertical-container gap-40 submit-bug-form">
      <h4>Bug Report Form</h4>
      <div>
        <label for="username" class="submit-bug-form-label h4-body">Please enter your name</label>
        <input id="username" name="username" class="submit-bug-form-input">
      </div>
      <div>
        <label for="useremail" class="submit-bug-form-label h4-body">Please enter your email address</label>
        <input id="useremail" name="useremail" class="submit-bug-form-input" type="email">
      </div>
      <div>
        <label for="issuedate" class="submit-bug-form-label h4-body">Date and Time of Issue</label>
        <input id="issuedate" name="issuedate" class="submit-bug-form-input" type="date">
      </div>
      <div class="pb-16 submit-bug-form-text">
        Please describe the problem you encountered in detail. Include steps to reproduce the issue if possible.
      </div>
      <div>
        <label for="issuedescription" class="submit-bug-form-label h4-body">Description of the Issue</label>
        <textarea id="issuedescription" name="issuedescription" rows="6" class="submit-bug-form-input">
        </textarea>
      </div>
      <div class="vertical-container gap-16 align-start">
        <h4>Screenshot/Attachment</h4>
        <div class="pb-16 submit-bug-form-text">
          Attach any screenshots or files that may help us understand the issue better
        </div>
        <button class="btn orange">
          <app-svg-icon iconPath="icons/attach"></app-svg-icon>
          BROWSE FILES
        </button>
      </div>
      <div>
        <div class="pb-16 submit-bug-form-text">
          If you have any additional information or comments, please include them here:
        </div>
        <label for="attachmentdescription" class="submit-bug-form-label h4-body">Additional comments (optional)</label>
        <textarea id="attachmentdescription" name="attachmentdescription" rows="6" class="submit-bug-form-input">
        </textarea>
      </div>
      <div>By submitting this bug report, you are assisting us in providing a better user experience for everyone. Our
        team will review the information provided and work to resolve the issue as quickly as possible. Thank you for
        your valuable feedback.
      </div>
      <button class="btn neon-teal submit-bug-form-submit" onclick="window.location.href='mailto:support@tikos.io';">
        SUBMIT A BUG REPORT
      </button>
    </form>
  </section>
  <div class="submit-bug-art">
    <div class="submit-bug-image-box"></div>
    <div class="submit-bug-image-box2"></div>
  </div>
</div>
