<div class="page-content">
  <h1 id="privacy-policy-title" class="background-box-always">PRIVACY POLICY</h1>

  <div>
    <p>This privacy policy will explain how Tikos uses the personal data we collect from you when you use our website or app.</p>

    <h3>What data do we collect?</h3>
    <ul>
      <li>Personal identification information (Name, email address, phone number)</li>
      <li>Social media account names (if you sign in using Google, Facebook, or another identity provider)</li>
    </ul>

    <h3>How do we collect your data?</h3>
    <p>You directly provide Tikos with most of the data we collect. We collect data and process data when you:</p>
    <ul>
      <li>Register online or place an order for any of our products or services.</li>
      <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
      <li>Use or view our website via your browser’s cookies.</li>
    </ul>

    <h3>How will we use your data?</h3>
    <p>Tikos collects your data so that we can:</p>
    <ul>
      <li>Process your order and manage your account.</li>
      <li>Email you about products or services you have indicated you are interested in (via wishlists).</li>
      <li>Email you about products or services we think you might be interested in.</li>
    </ul>
    <p>When Tikos processes your order, it may send your data to, and also use the resulting information from, credit card processors and/or credit reference agencies to prevent fraudulent purchases.</p>

    <h3>How do we store your data?</h3>
    <p>Tikos securely stores your data in Amazon Web Services. Your data is encrypted both in transit and at rest using the best available encryption technology, and our infrastructure is routinely scanned for security vulnerabilities.</p>
    <p>Tikos will keep your personal identification data while your account with us is active. If you deactivate your account, we will keep your data for a period of one year. Once this period expires, we will delete your data using an automated process. Your account will not be recoverable after your data has been deleted.</p>

    <h3>Marketing</h3>
    <p>Tikos would like to send you information about products and services of ours that we think you might like.</p>
    <p>If you have agreed to receive marketing, you may always opt out at a later date.</p>
    <p>You have the right at any time to stop Tikos from contacting you for marketing purposes.</p>
    <p>If you no longer wish to be contacted for marketing purposes, please <a href="#">click here</a>.</p>

    <h3>What are your data protection rights?</h3>
    <p>Tikos would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
    <ul>
      <li><strong>The right to access</strong> - You have the right to request Tikos for copies of your personal data. We may charge you a small fee for this service.</li>
      <li><strong>The right to rectification</strong> - You have the right to request that Tikos correct any information you believe is inaccurate. You also have the right to request Tikos to complete the information you believe is incomplete.</li>
      <li><strong>The right to erasure</strong> - You have the right to request that Tikos erase your personal data, under certain conditions.</li>
      <li><strong>The right to restrict processing</strong> - You have the right to request that Tikos restrict the processing of your personal data, under certain conditions.</li>
      <li><strong>The right to object to processing</strong> - You have the right to object to Tikos’s processing of your personal data, under certain conditions.</li>
      <li><strong>The right to data portability</strong> - You have the right to request that Tikos transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
    </ul>
    <p>If you make a request, we will respond to you within one month. If you would like to exercise any of these rights, please see the "How to Contact us" section of this policy.</p>

    <h3>Cookies</h3>
    <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.</p>
    <p>For further information, visit <a href="https://allaboutcookies.org" target="_blank">https://allaboutcookies.org</a>.</p>

    <h3>How do we use cookies?</h3>
    <p>Tikos uses cookies in a range of ways to improve your experience on our website, including:</p>
    <ul>
      <li>Keeping you signed in</li>
      <li>Understanding how you use our website</li>
    </ul>

    <h3>What types of cookies do we use?</h3>
    <p>There are a number of different types of cookies, however, our website uses:</p>
    <ul>
      <li><strong>Functionality</strong> - Tikos uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li>
      <li><strong>Advertising</strong> - Tikos uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address.</li>
    </ul>

    <h3>How to manage cookies</h3>
    <p>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>

    <h3>Privacy policies of other websites</h3>
    <p>The Tikos website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>

    <h3>Changes to our privacy policy</h3>
    <p>Tikos keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 8 April 2024.</p>

    <h3>How to contact us</h3>
    <p>If you have any questions about Tikos’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>
    <p>Email: <a href="mailto:info@tikos.io">info&AMP;tikos.io</a></p>
    <p>Call us:</p>
  </div>
</div>
