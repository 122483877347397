@if (tag) {
  <div class="horizontal-container gap-24">
    @if (tag.images?.length) {
      <swiper-container init="false">
        @for (image of tag.images; track image) {
          <swiper-slide>
            <img [src]="image" class="tag-image" alt="tag image" />
          </swiper-slide>
        }
      </swiper-container>
    }
  </div>
  <div class="width-100 vertical-container gap-16">
    <div class="vertical-container gap-8">
      <div class="tag-name">{{tag.name}}</div>
      <div>by {{tag.organization?.name}}</div>
    </div>
    <div class="tag-description">
      {{tag.description}}
    </div>

    <div class="vertical-container details gap-12">
      @if (tag.serialNumber) {
        <div class="width-100 horizontal-container space-between">
          <div class="detail-title">Serial Number</div>
          <div class="detail-value">{{tag.serialNumber}}</div>
        </div>
      }

      <div class="width-100 horizontal-container space-between">
        <div class="detail-title">Category</div>
        <div class="detail-value">{{tag.details.category}}</div>
      </div>

      <div class="width-100 horizontal-container space-between">
        <div class="detail-title">Production Date</div>
        <div class="detail-value">{{tag.details.productionDate | date: 'mediumDate'}}</div>
      </div>
    </div>
  </div>
} @else if(loaded) {
  <p>Tag not found</p>
}
