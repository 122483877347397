import {Component, Input} from '@angular/core';
import {NgStyle} from "@angular/common";

@Component({
  selector: 'app-svg-icon',
  standalone: true,
  imports: [
    NgStyle
  ],
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss'
})
export class SvgIconComponent {
  @Input() iconPath!: string;
  @Input() iconId = 'c';

  // null, because if an attribute is null, it will not be added at all
  @Input() viewBox: string | null = null;
  @Input() width: number | null = null;
  @Input() height: number | null = null;

  constructor() { }

  svgPath(): string {
    return `assets/${this.iconPath}.svg#${this.iconId}`;
  }

  getStyles() {
    return {
      width: this.width ? this.width : '24px',
      height: this.height ? this.height : '24px',
    };
  }

}
