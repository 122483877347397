import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable, throwError} from "rxjs";
import {Tag} from "../interfaces/tag.interfaces";

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private readonly ROOT = `${environment.rootUrl}/tags/`

  constructor(private http: HttpClient) { }

  getTagById$(id: number): Observable<Tag> {
    return this.http.get<any>(`${this.ROOT}${id}`).pipe(
      map(value => {
        return value as Tag;
      }),
      catchError(err => {
          return throwError(() => Error(err.status + ' Exception while getting tag by id. ' + err.error?.message))
        }
      ));
  }

  getTagFromEncrypted$(picc: string, enc: string, cmac: string): Observable<Tag> {
    return this.http.get<any>(`${this.ROOT}from-encrypted/${enc}/${picc}/${cmac}`).pipe(
      map(value => {
        return value as Tag;
      }),
      catchError(err => {
          return throwError(() => Error(err.status + ' Exception while getting tag by encrypted. ' + err.error?.message))
        }
      ));
  }
}
