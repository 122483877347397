import { Component } from '@angular/core';
import { SubscribeComponent } from '../../components/subscribe/subscribe.component';

@Component({
  selector: 'app-terms-of-use',
  standalone: true,
  imports: [
    SubscribeComponent
  ],
  templateUrl: './terms-of-use.component.html',
  styleUrl: './terms-of-use.component.scss'
})
export class TermsOfUseComponent {

}
