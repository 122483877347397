<div class="page-content">
  <section class="sub-heading vertical-container gap-16 align-start">
    <h1 id="help-center-title" class="background-box-always">HELP CENTER</h1>
    <div class="sub-heading-text page-content-description">Welcome to our Help Center page, your go-to resource for
      quick
      answers to commonly asked
      questions. Whether you're curious about our products, services, policies, or anything in between, this curated
      list of frequently asked questions is here to provide clarity and convenience. We understand that you may have
      queries, and our aim is to address them comprehensively in one convenient location. Browse through the sections
      below to find the information you need, and feel free to reach out if you have a question that isn't covered here.
      Thank you for visiting, and let's get those questions answered!
    </div>
  </section>

  <section>
    <article class="vertical-container gap-24 options">
      <ol class="options-content">
        <li>Purchasing chips
          <ol>
            <li>Set up an account on your phone (IOS / Android)</li>
            <li>Decide on what chips are right for you <a target="_blank" href="https://store.tikos.io/">here</a></li>
            <li>Select the amount and provide email address</li>
            <li>Chips will show up in a few days via US Postal Service</li>
            <li>Once you receive your chips, follow the instructions for putting your chips on the blockchain</li>
          </ol>
        </li>
        <li>Putting your chips on the blockchain
          <ol>
            <li>When you receive your Tikos chips, decide what brand you’d like your chips to be under.</li>
            <li>Prepare your chip by attaching it to your asset.</li>
            <li>Take a photo or more of you doing this or after it’s been attached. The more documentation, the
              better.
            </li>
            <li>Mint your chip.</li>
          </ol>
        </li>
        <li>Turning on NFC on android phones
          <ol>
            <li>On your Android device, open the Settings app. In the search bar, type “NFC.” Make sure that the NFC
              setting is toggled on.
            </li>
          </ol>
        </li>
        <li>Turning on NFC on apple phones
          <ol>
            <li>On your iPhone, open the Shortcuts app.</li>
            <li>Tap on the Automation tab at the bottom of your screen.</li>
            <li>Tap on Create Personal Automation.</li>
            <li>Scroll down and select NFC.</li>
            <li>Tap on Scan.</li>
            <li>Put your iPhone near the NFC tag.</li>
            <li>Enter a name for your tag.</li>
            <li>Tap on Add Action.</li>
          </ol>
        </li>
        <li>Which chip is right for you?
          <ol>
            <li>Please see the descriptions of each chip to determine what use case fits your needs. Some things to
              consider are:
              <ol type="a">
                <li>Is my asset going to get wet or live outdoors?</li>
                <li>Is my asset metal?</li>
                <li>Do I need tamper evidence of my asset?</li>
                <li>Is my asset very flexible (clothing)?</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Return policy
          <ol>
            <li>Unfortunately, once your asset is minted and shipped, it’s on the blockchain and there is no putting
              that genie back in the bottle and that’s the kind of security we’re going after.
            </li>
          </ol>
        </li>
        <li>Contact customer support
          <ol>
            <li>We provide cutting-edge authentication technology that verifies the authenticity of your physical assets
              and seamlessly links them to a secure digital twin. Say goodbye to counterfeits and fraud, and hello to
              effortless provenance tracking and ownership verification. Visit our website or contact us today to learn
              how [Your Company Name] can safeguard your most prized possessions. <a href="mailto:support@tikos.io">support&#64;tikos.io</a>
            </li>
          </ol>
        </li>
        <li>How do I know my asset is 1 of kind?
          <ol>
            <li>You’ll be able to see that in the marketplace and on the blockchain.</li>
          </ol>
        </li>
        <li>Who authenticates my asset?
          <ol>
            <li>For starters, you should be the creator of your asset. You are responsible for your own authentication
              by attaching a Tikos chip to it.
            </li>
            <li>If you’d like to verify other Tikos assets, you can create a brand and establish credibility within the
              Tikos community.
            </li>
          </ol>
        </li>
        <li>How do I run a batch job of minting many chips?
          <ol>
            <li>TBD</li>
          </ol>
        </li>
        <li>What are brands?
          <ol>
            <li>Brands are our way of allowing you to have multiple tikos identities.</li>
            <li>For example, you might be an photographer that sells your authentic photos. That’s your default brand.
              Then you might work for an auction house and authenticate artwork for a living. This would be another
              brand.
            </li>
          </ol>
        </li>
        <li>Can I use my own NFC chip?
          <ol>
            <li>In order to be the most secure platform every created, we at Tikos embed our own digital signature in
              every one of our chips. This provides an additional layer of security. If there were to be 2 chips created
              with the same Chip ID, the Tikos digital signature would differentiate the two chips.
            </li>
            <li>All of that to say, no you can’t use your own chips because we have no way of embedding our digital
              signature into that chip to ensure authenticity.
            </li>
          </ol>
        </li>
      </ol>
    </article>
  </section>
  <app-subscribe class="self-center"/>
</div>
