<div class="page-content">
  <div class="vertical-container gap-40 terms-of-use align-start">
    <h1 id="terms-of-use-title" class="background-box-always">TERMS OF USE</h1>
    <p class="page-content-description">Welcome to our FAQ page, your go-to resource for quick answers to commonly asked
      questions. Whether you're curious about our products, services, policies, or anything in between, this curated
      list of frequently asked questions is here to provide clarity and convenience. We understand that you may have
      queries, and our aim is to address them comprehensively in one convenient location. Browse through the sections
      below to find the information you need, and feel free to reach out if you have a question that isn't covered here.
      Thank you for visiting, and let's get those questions answered!</p>
    <div class="vertical-container gap-16">
      <h3>Disclaimer of Warranties</h3>
      <p class="term-description">To the fullest extent allowed by applicable law, Tikos disclaims all
        warranties, representations, and
        guarantees regarding the content of this website. We do not warrant that the information provided is
        accurate, complete, or up to date, nor do we guarantee that following any recommendations or
        guidance on this site will yield specific results. The use of this website is at your own risk, and we make
        no guarantees that it will be free from harmful components such as viruses or malware.</p>
    </div>
    <div class="vertical-container gap-16">
      <h3>Limitation of Liability</h3>
      <p class="term-description">Tikos will not be liable for any loss or damage of any nature (direct,
        indirect, consequential, or
        otherwise) arising from the use or inability to use this website. This includes, but is not limited to, losses
        related to profits, data, contracts, business opportunities, or goodwill.</p>
      <p class="term-description">Further, we will not be responsible for any damage arising from events outside of our
        control. We
        cannot guarantee that this website will always be available, free from disruptions, or safe from security
        vulnerabilities.</p>
    </div>
    <div class="vertical-container gap-16">
      <h3>Third-Party Links and Content</h3>
      <p class="term-description">Our website may include links to third-party websites for your convenience. Tikos does
        not control these
        external websites, and we are not responsible for their content, functionality, or data practices. Clicking
        on third-party links does not imply an endorsement of the site or its offerings. Be aware that these
        external websites may collect data or contain harmful components like viruses. Tikos will not assume
        any liability for third-party websites or their content.</p>
    </div>
    <div class="vertical-container gap-16">
      <h3>Data and Security</h3>
      <p class="term-description">While we take reasonable measures to protect the integrity of our website, Tikos
        cannot guarantee that
        your interactions with our site will be free from risks such as data breaches, corruption, or software
        issues. We are not responsible for any loss or corruption of data, databases, or software that may occur
        while using our services.</p>
    </div>
    <div class="vertical-container gap-16">
      <h3>General Limitations</h3>
      <p class="term-description">Nothing in these terms will limit or exclude our liability for death or personal
        injury resulting from
        negligence, fraud, or other liabilities that cannot be legally excluded or limited by applicable law. Any
        limitations stated here apply to all liabilities, including those arising from contract, tort (including
        negligence), and breaches of statutory duty.</p>
    </div>
    <div class="vertical-container gap-16">
      <h3>Severability</h3>
      <p class="term-description">If any part of these terms is found to be invalid or unenforceable under applicable
        law, the affected
        provision will be enforced to the maximum extent permitted, and the remaining provisions will continue
        to be fully valid and enforceable.</p>
    </div>
  </div>
  <app-subscribe class="self-center"/>
</div>
