<div class="page-content">
  <section class="horizontal-container use-cases-intro">
    <div class="vertical-container gap-48 align-start use-cases-intro-info">
      <h1 class="page-title">USE CASES</h1>
      <h3 id="use-cases-title" class="background-box-always">PRACTICAL IMPLEMENTATIONS</h3>
      <div>Welcome to our Use Cases page, where we explore the countless real-world scenarios in which Tikos chips
        create value by bridging the physical and digital worlds. Here, we showcase the versatility and practical
        applications of our product across various contexts. The possibilities are endless, limited only by your
        imagination. Whether you are seeking inspiration, looking for practical applications, or evaluating the
        versatility
        of our offerings, our use cases highlight the versatility and effectiveness of our product/service in diverse
        contexts. Explore the following examples to see how our solutions can help you achieve your goals and drive
        success in your own projects.
      </div>
      <button class="btn neon-teal hidden">
        GIVE IT A TRY
        <app-svg-icon iconPath="icons/call_made"></app-svg-icon>
      </button>
    </div>
    <div class="use-cases-intro-img-block">
      <img class="use-cases-intro-img" alt="" src="assets/images/chip_photo.png">
    </div>
  </section>

  <section>
    <article class="horizontal-container gap-48 cases">
      <div class="vertical-container gap-24 case align-start">
        <div class="case-image">
          <img alt="" src="assets/images/athlete.jpeg">
        </div>
        <div class="case-title">Athletes and Influencers</div>
        <div class="case-description">In today’s everchanging NIL and Influencer landscape, it’s more important than
          ever to protect your personal brand and receive the royalties you deserve. Rapid advances in AI are making it
          exponentially more challenging to spot and identify deepfakes that can damage your reputation causing
          emotional distress, financial loss and costly legal issues. With Tikos, you can mitigate deepfakes and
          unauthorized use of your property by establishing a permanent link to your identity and have complete control
          of the content you want to release.
        </div>
      </div>

      <div class="vertical-container gap-24 case align-start">
        <div class="case-image">
          <img alt="" src="assets/images/artwork.jpeg">
        </div>
        <div class="case-title">Artwork</div>
        <div class="case-description">As an artist, everything you create is unique and one of a kind. Digitally
          autograph your artwork to show that you’re one of a kind by establishing the first link in its history forever
          proving you as the Creator. Want to sell your artwork? Using the Tikos app, each peer-to-peer transaction is
          recorded providing the history through all time safeguarding transactions. Creating a limited run? Prove only
          that number is in existence with Tikos chips.
        </div>
      </div>

      <div class="vertical-container gap-24 case align-start">
        <div class="case-image">
          <img alt="" src="assets/images/artists.jpeg">
        </div>
        <div class="case-title">Artist</div>
        <div class="case-description">Have you created something? Put a Tikos chip on it to digitally authenticate the
          product as yours and not a replica. Selling a limited run of your product? Prove that there are only that many
          of your products in existence. Everything you link to a Tikos chip is forever traceable, with ownership and
          authenticity transparently stored on the blockchain. This guarantees that no matter where your product goes,
          its history and rightful ownership are always securely linked to you.
        </div>
      </div>

      <div class="vertical-container gap-24 case align-start">
        <div class="case-image">
          <img alt="" src="assets/images/collectibles.jpeg">
        </div>
        <div class="case-title">Collectibles</div>
        <div class="case-description">Authentication services can cost hundreds to thousands of dollars per asset.
          Coupled with a TIKOS chip, Professional Authenticators who are experts in their field can now digitally record
          notes, photos and legal documentation to verify the authenticity of various kinds of artwork, not only
          increasing the value of the asset itself, but promoting their brand as well. In addition, Tikos allows for
          multiple experts to authenticate a single asset, something no one else in the business can do.
        </div>
      </div>

      <div class="vertical-container gap-24 case align-start">
        <div class="case-image">
          <img alt="" src="assets/images/memorabilia.png">
        </div>
        <div class="case-title">Memorabilia</div>
        <div class="case-description">You got the signature and now you can record the details forever linking the proof
          to your artifact. Whether or not you intend for it to change hands, peace of mind will always be there knowing
          its indisputable history is secured on the blockchain. Athletes with licensing agreements have the ability to
          collect royalties each time the asset is sold in the secondary market.
        </div>
      </div>

      <div class="vertical-container gap-24 case align-start">
        <div class="case-image">
          <img alt="" src="assets/images/spirits.png">
        </div>
        <div class="case-title">Wine & Spirits</div>
        <div class="case-description">Each innovative Tikos ID chip provides a comprehensive transaction history,
          tracking the bottle’s journey from production to purchase and ensuring authenticity and provenance. The
          tamper-evident design detects any opening, protecting the contents from fraud and offering unparalleled
          transparency. By leveraging blockchain, Tikos creates a secure, unalterable digital record that tracks each
          bottle from the vineyard or distillery to the consumer. This permanent record of ownership and transaction
          history builds trust, guaranteeing the bottle’s authenticity and ensuring its contents are exactly as claimed,
          giving buyers complete confidence in the quality and origin of their wine or spirits.
        </div>
      </div>

      <div class="vertical-container gap-24 case align-start">
        <div class="case-image">
          <img alt="" src="assets/images/brand_authenticity.jpeg">
        </div>
        <div class="case-title">Brand Authenticity</div>
        <div class="case-description">Counterfeit merchandise costs the global economy an estimated $500 billion
          annually. While financial losses are great, the broader effects can have far greater consequences including
          job losses, increased organized crime, property infringement, loss of tax revenue and consumer trust. While
          there will always be a market for knock-offs, what happens when customers pay for and think they are getting
          the real thing? Tikos brings your company and customer authentically together protecting your brand and
          fortifying trust.
        </div>
      </div>
    </article>
  </section>
  <app-subscribe class="self-center"/>
</div>
