import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  private readonly ROOT = `${environment.rootUrl}/auth`; // Replace with your API URL

  constructor(private http: HttpClient) { }

  confirmEmail$(token: string): Observable<any> {
    return this.http.get(`${this.ROOT}/confirm-email/${token}`);
  }
}
