import { Component } from '@angular/core';
import {AuthServiceService} from "../../services/auth-service.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-email-confirmation-result',
  standalone: true,
  imports: [],
  templateUrl: './email-confirmation-result.component.html',
  styleUrl: './email-confirmation-result.component.scss'
})
export class EmailConfirmationResultComponent {
  emailConfirmed = false;
  badToken = false;

  constructor(private authServiceService: AuthServiceService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      const token = params['token'];

      this.authServiceService.confirmEmail$(token).subscribe({
        next: () => {
          this.emailConfirmed = true;
        },
        error: () => {
          this.badToken = true;
        }
      });
    })
  }
}
