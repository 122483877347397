import { Component, Inject, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { DropdownMenuComponent, DropdownMenuItem } from "./components/dropdown-menu/dropdown-menu.component";
import { isPlatformBrowser } from '@angular/common';
import { ModalComponent } from "./components/modal/modal.component";


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SvgIconComponent, RouterLink, RouterLinkActive, DropdownMenuComponent, ModalComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'tikos-landing';

  supportNavItems: DropdownMenuItem[] = [
    { label: 'Help Center', route: '/help-center' },
    { label: 'Contact US', route: '/contact-us' },
    { label: 'Submit a bug', route: '/submit-bug' },
  ];

  communityNavItems: DropdownMenuItem[] = [
    { label: 'Reddit', link: 'https://reddit.com/r/Tikos' },
  ];

  dropdownNavOpen = signal(false);

  storeLink: string = '';

  constructor(public router: Router, @Inject(PLATFORM_ID) private platformId: object) {
  }

  ngOnInit(): void {
    // Only run this if we are in the browser environment
    if (isPlatformBrowser(this.platformId)) {
      this.setStoreLink();
    }
  }

  // Function to detect the OS and set the store link accordingly
  setStoreLink(): void {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera' as any];

    if (/android/i.test(userAgent as any)) {
      // If the device is Android, set the Google Play Store link
      this.storeLink = 'https://play.google.com/store/apps/details?id=your-app-id';
    } else if (/iPad|iPhone|iPod/.test(userAgent as any) && !window['MSStream' as any]) {
      // If the device is iOS, set the Apple App Store link
      this.storeLink = 'https://apps.apple.com/app/idyour-app-id';
    } else {
      // Default link if OS is neither Android nor iOS
      this.storeLink = 'https://tikos.io';
    }
  }

  // Function to open the app store when the button is clicked
  openStore(): void {
    window.open(this.storeLink, '_blank');
  }

  navToRoot() {
    this.router.navigate(['/']);
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
