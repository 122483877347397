import { Component } from '@angular/core';
import { SvgIconComponent } from '../../components/svg-icon/svg-icon.component';
import { SubscribeComponent } from '../../components/subscribe/subscribe.component';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    SvgIconComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {
  emailField = new FormControl('', [Validators.required, Validators.email]);

  subscribeToNewsletter() {
    console.log('Subscribing to newsletter with email:', this.emailField.value)
  }
}
