<div class="dropdown-menu-container cursor-pointer">
  <div class="nav-link horizontal-container align-center clients-label background-hover-box"
       [ngClass]="{'active': menuOpened()}"
       style="z-index: 1"
       (click)="menuOpened.set(!menuOpened())">
    {{ label() }}
    <app-svg-icon iconPath="icons/chevron-down" style="margin-left: 4px"></app-svg-icon>
  </div>

  @if (menuOpened()) {
    <div class="dropdown-menu" [ngClass]="{absolute: absolute()}">
      @for (item of items(); track item.label) {

        @if (item.route) {
          <a [routerLink]="item.route" routerLinkActive="active"
             class="nav-link background-hover-box">{{ item.label }}</a>
        } @else {
          <a href="{{item.link}}" target="_blank"
             routerLinkActive="active"
             class="nav-link background-hover-box">{{ item.label }}</a>
        }
      }
    </div>
  }
</div>
