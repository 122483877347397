import { Component } from '@angular/core';
import { SvgIconComponent } from '../../components/svg-icon/svg-icon.component';
import { SubscribeComponent } from '../../components/subscribe/subscribe.component';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-help-center',
  standalone: true,
  imports: [
    SvgIconComponent,
    SubscribeComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './help-center.component.html',
  styleUrl: './help-center.component.scss'
})
export class HelpCenterComponent {
}
