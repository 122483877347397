<div class="page-content">
  <section class="vertical-container gap-40 align-start contact-us">
    <h1 id="contact-us-title" class="background-box-always page-title">CONTACT US</h1>
    <div>
      <p>Customer needs to put in a small message describing their question and an optional phone number along w/ their
        email address in order to submit the ticket.
        <br/>Phone number: optional
      </p>
      <a class="bold" href="mailto:info@tikos.io">info&#64;tikos.io</a>
    </div>

    <div>
      <div class="contact-us-invite">Contact us and we'll be happy to provide a demo and answer any questions you may
        have.
      </div>
      <div class="phone-adaptive-container gap-16 width-100">
        <input type="email" placeholder="Enter your email address" class="width-100" [formControl]="emailField">
        <button class="btn yellow" (click)="subscribeToNewsletter()">
          <app-svg-icon iconPath="icons/app_badging"></app-svg-icon>
          SEND
        </button>
      </div>
    </div>
  </section>
  <div class="contact-us-art">
    <div class="contact-us-image-box"></div>
    <div class="contact-us-image-box2"></div>
  </div>
</div>
