import { Component, signal } from '@angular/core';
import { SvgIconComponent } from "../../components/svg-icon/svg-icon.component";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { ModalComponent } from "../../components/modal/modal.component";
import { SubscribeComponent } from '../../components/subscribe/subscribe.component';
import { RouterLink } from '@angular/router';

interface GalleryItem {
  title: string;
  text: string;
  images: string[];
}


@Component({
  selector: 'app-main-page',
  standalone: true,
  imports: [
    SvgIconComponent,
    SubscribeComponent,
    ReactiveFormsModule,
    ModalComponent,
    RouterLink
  ],
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.scss'
})
export class MainPageComponent {
  emailField = new FormControl('', [Validators.required, Validators.email]);

  galleryItems: GalleryItem[] = [
    {
      title: 'Athletes and Influencers',
      text: 'In today’s everchanging NIL and Influencer landscape, it’s more important than ever to protect your personal brand and receive the royalties you deserve. Rapid advances in AI are making it exponentially more challenging to spot and identify deepfakes that can damage your reputation causing emotional distress, financial loss and costly legal issues. \n With Tikos, you can mitigate deepfakes and unauthorized use of your property by establishing a permanent link to your identity and have complete control of the content you want to release.',
      images: ['assets/images/athlete.jpeg']
    },
    {
      title: 'Artwork',
      text: 'As an artist, everything you create is unique and one of a kind. Digitally autograph your artwork to show that you’re one of a kind by establishing the first link in its history forever proving you as the Creator. Want to sell your artwork? Using the Tikos app, each peer-to-peer transaction is recorded providing the history through all time safeguarding transactions. Creating a limited run? Prove only that number is in existence with Tikos chips.',
      images: ['assets/images/artwork.jpeg']
    },
    {
      title: 'Collectibles',
      text: 'Authentication services can cost hundreds to thousands of dollars per asset. Coupled with a TIKOS chip, Professional Authenticators who are experts in their field can now digitally record notes, photos and legal documentation to verify the authenticity of various kinds of artwork, not only increasing the value of the asset itself, but promoting their brand as well. In addition, Tikos allows for multiple experts to authenticate a single asset, something no one else in the business can do.',
      images: ['assets/images/collectibles.jpeg']
    },
    {
      title: 'Artists',
      text: 'Have you created something?  Put a Tikos chip on it to digitally authenticate the product as yours and not a replica.  Selling a limited run of your product? Prove that there are only that many of your products in existence. Everything you link to a Tikos chip is forever traceable, with ownership and authenticity transparently stored on the blockchain. This guarantees that no matter where your product goes, its history and rightful ownership are always securely linked to you.',
      images: ['assets/images/artists.jpeg']
    },
    {
      title: 'Memorabilia',
      text: 'You got the signature and now you can record the details forever linking the proof to your artifact. Whether or not you intend for it to change hands, peace of mind will always be there knowing its indisputable history is secured on the blockchain. Athletes with licensing agreements have the ability to collect royalties each time the asset is sold in the secondary market.',
      images: ['assets/images/memorabilia.png']
    },
    {
      title: 'Wine & Spirits',
      text: 'Each innovative Tikos ID chip provides a comprehensive transaction history, tracking the bottle’s journey from production to purchase and ensuring authenticity and provenance. The tamper-evident design detects any opening, protecting the contents from fraud and offering unparalleled transparency. \n By leveraging blockchain, Tikos creates a secure, unalterable digital record that tracks each bottle from the vineyard or distillery to the consumer. This permanent record of ownership and transaction history builds trust, guaranteeing the bottle’s authenticity and ensuring its contents are exactly as claimed, giving buyers complete confidence in the quality and origin of their wine or spirits.',
      images: ['assets/images/spirits.png']
    },
    {
      title: 'Brand Authenticity',
      text: 'Counterfeit merchandise costs the global economy an estimated $500 billion annually. While financial losses are great, the broader effects can have far greater consequences including job losses, increased organized crime, property infringement, loss of tax revenue and consumer trust. While there will always be a market for knock-offs, what happens when customers pay for and think they are getting the real thing? \n Tikos brings your company and customer authentically together protecting your brand and fortifying trust.',
      images: ['assets/images/brand_authenticity.jpeg']
    }
  ]

  selectedGalleryItem = signal<GalleryItem | null>(null);

  subscribeToNewsletter() {
    console.log('Subscribing to newsletter with email:', this.emailField.value)
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
