import { Component } from '@angular/core';
import { SvgIconComponent } from '../../components/svg-icon/svg-icon.component';
import { SubscribeComponent } from '../../components/subscribe/subscribe.component';

@Component({
  selector: 'app-security-stack',
  standalone: true,
  imports: [
    SvgIconComponent,
    SubscribeComponent,
  ],
  templateUrl: './security-stack.component.html',
  styleUrl: './security-stack.component.scss'
})
export class SecurityStackComponent {
}
